<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Enviar PIX</h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="card">
        <div class="card-body" v-if="!docsOk">
          <div class="row">
            <div class="col-md-12 text-center">
              <ul class="list-unstyled my-4">
                <li>
                  <div class="d-flex align-items-center">
                    <div>
                      <div class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                        <i class="fas fa-arrow-right"></i>
                      </div>
                    </div>
                    <div>
                      <span class="pl-2 text-md">Antes de enviar um PIX, é necessário verificar a conta, completando o seu cadastro.</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-md-12 text-center">
              <router-link to="/pages/user" class="btn btn-primary">
                <span class="btn-label"><i class="fas fa-tasks"></i> COMPLETAR CADASTRO</span>
              </router-link>
            </div>
          </div>
        </div>
        <div class="card-body" v-else>
          <div class="row">
            <div class="col-md-6" v-if="!showHistory && isNullOrEmpty(paymentInitialized.paymentId)">
              <div class="row">
                <div class="col-md-12">
                  <label class="control-label float-right" style="margin-bottom: 0 !important;">
                    <a href="#" @click="loadHistory">
                      <i slot="label" class="fas fa-list"></i> Últimos Destinos</a>
                  </label>
                  <base-input label="Escolha o tipo de chave de identificação">
                    <el-select size="large" placeholder="Selecionar" style="width: 100%" v-model="destinationIdType">
                      <el-option class="select-info"
                                 value="CPF"
                                 label="CPF"/>
                      <el-option class="select-info"
                                 value="CNPJ"
                                 label="CNPJ"/>
                      <el-option class="select-info"
                                 value="PHONE"
                                 label="Celular"/>
                      <el-option class="select-info"
                                 value="EMAIL"
                                 label="E-mail"/>
                      <el-option class="select-info"
                                 value="MAN"
                                 label="Agência e Conta"/>
                      <el-option class="select-info"
                                 value="EVP"
                                 label="Chave Aleatória"/>
                      <el-option class="select-info"
                                 value="QRCODE"
                                 label="QR Code (Copia e Cola)"/>
                    </el-select>
                  </base-input>
                </div>
              </div>
              <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                <div v-if="notNullOrEmpty(destinationIdType) && destinationIdType !== 'MAN'">
                  <div class="row mt-2">
                    <div class="col-md-12">
                      <base-input
                        v-if="destinationIdType === 'CPF'"
                        type="text"
                        label="CPF Destino"
                        name="CPF"
                        placeholder="###.###.###-##"
                        v-mask="['###.###.###-##']"
                        :rules="{required: true, cpfCnpjValid: true}"
                        v-model="transfer2Key.key.value">
                      </base-input>
                      <base-input
                        v-if="destinationIdType === 'CNPJ'"
                        type="text"
                        label="CNPJ Destino"
                        name="CNPJ"
                        placeholder="##.###.###/####-##"
                        v-mask="['##.###.###/####-##']"
                        :rules="{required: true, cpfCnpjValid: true}"
                        v-model="transfer2Key.key.value">
                      </base-input>
                      <base-input
                        v-if="destinationIdType === 'PHONE'"
                        type="text"
                        label="Celular de Destino"
                        name="Celular"
                        placeholder="(00) 000-000-000"
                        v-mask="['(##) #####-####']"
                        :rules="{required: true}"
                        v-model="transfer2Key.key.value">
                      </base-input>
                      <base-input
                        v-if="destinationIdType === 'EMAIL'"
                        type="text"
                        label="E-mail de Destino"
                        name="E-mail"
                        placeholder="destino@email.com.br"
                        :rules="{required: true, email: true}"
                        v-model="transfer2Key.key.value">
                      </base-input>
                      <base-input
                        v-if="destinationIdType === 'EVP'"
                        type="text"
                        label="Chave aleatória"
                        name="Chave aleatória"
                        :rules="{required: true, length: 36}"
                        v-model="transfer2Key.key.value">
                      </base-input>
                      <base-input
                        v-if="destinationIdType === 'QRCODE'"
                        type="text"
                        label="QR Code (Copia e Cola)"
                        name="QR Code"
                        :rules="{required: true}"
                        v-model="transfer2Qr.value">
                      </base-input>
                    </div>
                  </div>
                </div>
                <div v-if="destinationIdType === 'MAN'">
                  <div class="row" style="margin-top: 10px;">
                    <div class="col-md-12">
                      <base-input label="Banco de Destino">
                        <autocomplete ref="bankAc"
                                      placeholder="Selecionar"
                                      :source="banksCodes"
                                      input-class="form-control"
                                      results-value="code"
                                      :results-display="displayBankName"
                                      @selected="selectBank">
                        </autocomplete>
                      </base-input>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-md-4">
                      <base-input
                        type="text"
                        label="Agência Destino"
                        name="Agência"
                        placeholder="Agência Destino"
                        v-mask="['####']"
                        :rules="{required: true}"
                        v-model="transfer2Account.branch">
                      </base-input>
                    </div>
                    <div class="col-md-4">
                      <base-input
                        type="text"
                        label="Conta Destino (com dígito)"
                        name="Conta Destino"
                        placeholder="Conta Destino"
                        v-mask="['############']"
                        :rules="{required: true}"
                        v-model="transfer2Account.number">
                      </base-input>
                    </div>
                    <div class="col-md-4">
                      <base-input label="Tipo de conta" :rules="{required: true}" name="Tipo de conta">
                        <el-select size="large" placeholder="Selecionar" style="width: 100%"
                                   v-model="transfer2Account.accountType">
                          <el-option class="select-info"
                                     value="CC"
                                     label="Conta Corrente"/>
                          <el-option class="select-info"
                                     value="PP"
                                     label="Poupança"/>
                          <el-option class="select-info"
                                     value="CS"
                                     label="Conta Salário"/>
                        </el-select>
                      </base-input>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <base-input
                        type="text"
                        label="Nome Destino"
                        name="Nome Destino"
                        placeholder="Nome Destino"
                        :rules="{required: true, max: 30}"
                        v-model="transfer2Account.receiverName">
                      </base-input>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <base-input label="Tipo de Documento" :rules="{required: true}" name="Tipo de documento">
                        <el-select size="large" placeholder="Selecionar" style="width: 100%"
                                   v-model="transfer2Account.receiverDocumentType">
                          <el-option class="select-info"
                                     value="CPF"
                                     label="CPF"/>
                          <el-option class="select-info"
                                     value="CNPJ"
                                     label="CNPJ"/>
                        </el-select>
                      </base-input>
                    </div>
                    <div class="col-md-6">
                      <base-input
                        v-if="transfer2Account.receiverDocumentType === 'CPF'"
                        type="text"
                        label="CPF Destino"
                        name="CPF"
                        placeholder="###.###.###-##"
                        v-mask="['###.###.###-##']"
                        :rules="{required: true, cpfCnpjValid: true}"
                        v-model="transfer2Account.receiverDocument">
                      </base-input>
                      <base-input
                        v-if="transfer2Account.receiverDocumentType === 'CNPJ'"
                        type="text"
                        label="CNPJ Destino"
                        name="CNPJ"
                        placeholder="##.###.###/####-##"
                        v-mask="['##.###.###/####-##']"
                        :rules="{required: true, cpfCnpjValid: true}"
                        v-model="transfer2Account.receiverDocument">
                      </base-input>
                    </div>
                  </div>
                </div>
                <br/>
                <div class="row text-center">
                  <div class="col-md-12 mb-3">
                    <button type="button" class="btn btn-primary" @click.prevent="handleSubmit(doTransaction)">
                      <span class="btn-label"><i class="fa fa-arrow-up"></i></span> Transferir
                    </button>
                  </div>
                </div>
              </validation-observer>
            </div>
            <div class="col-md-6" v-if="!isNullOrEmpty(paymentInitialized.paymentId)">
              <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                <div class="card">
                  <div class="card-body">
                    <div class="row justify-content-between align-items-center">
                      <div class="col">
                      <span class="h7 surtitle text-muted">
                        Confira os dados do seu PIX
                      </span>
                      </div>
                      <div class="col-auto">
                        <img src="/img/icons/logo-pix-icone-256.png" alt="Pix" height="36">
                        <span class="badge badge-lg" :class="`badge-success`"> PIX </span>
                      </div>
                    </div>
                    <hr/>
                    <!-- RECEIVER -->
                    <div class="row">
                      <div class="col">
                        <span class="h7 surtitle text-muted">
                          Dados do Recebedor
                        </span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <span class="h6 surtitle text-muted">Nome</span>
                        <span class="d-block h4">{{paymentInitialized.receiver.name}}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <span class="h6 surtitle text-muted">Documento</span>
                        <span class="d-block h4">{{ paymentInitialized.receiver.documentType }}  - {{ paymentInitialized.receiver.documentNumber|VueMaskFilter(receiverDocumentMask) }}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <span class="h6 surtitle text-muted">Banco</span>
                        <span class="d-block h4">{{paymentInitialized.receiver.bankName}}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <span class="h6 surtitle text-muted">Agência</span>
                        <span class="d-block h4">{{paymentInitialized.receiver.bankAccountBranch}}</span>
                      </div>
                      <div class="col">
                        <span class="h6 surtitle text-muted">Conta</span>
                        <span class="d-block h4">{{paymentInitialized.receiver.bankAccountNumber}}</span>
                      </div>
                      <div class="col">
                        <span class="h6 surtitle text-muted">Tipo de Conta</span>
                        <span
                          class="d-block h4">{{paymentInitialized.receiver.bankAccountTypeHR}}</span>
                      </div>
                    </div>
                    <!-- PAGADOR -->
                    <hr>
                    <div class="row">
                      <div class="col">
                        <span class="h7 surtitle text-muted">
                          Dados do Pagador
                        </span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <span class="h6 surtitle text-muted">Nome</span>
                        <span class="d-block h4">{{paymentInitialized.payer.name}}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <span class="h6 surtitle text-muted">Documento</span>
                        <span class="d-block h4">{{paymentInitialized.payer.documentType}} - {{paymentInitialized.payer.documentNumber|VueMaskFilter(payerDocumentMask)}}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <span class="h6 surtitle text-muted">Banco</span>
                        <span class="d-block h4">{{paymentInitialized.payer.bankName}}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <span class="h6 surtitle text-muted">Agência</span>
                        <span class="d-block h4">{{paymentInitialized.payer.bankAccountBranch}}</span>
                      </div>
                      <div class="col">
                        <span class="h6 surtitle text-muted">Conta</span>
                        <span class="d-block h4">{{paymentInitialized.payer.bankAccountNumber}}</span>
                      </div>
                      <div class="col">
                        <span class="h6 surtitle text-muted">Tipo de Conta</span>
                        <span
                          class="d-block h4">{{paymentInitialized.payer.bankAccountTypeHR}}</span>
                      </div>
                    </div>
                    <hr>
                    <!-- VALUE -->
                    <div class="my-4">
                    <span class="h6 surtitle text-muted">
                      Valor
                    </span>
                      <div class="h3" v-if="!valueChangeAllowed">R$ {{paymentInitialized.value}}</div>
                      <div v-if="valueChangeAllowed">
                        <base-input placeholder="0,00"
                                    type="text"
                                    name="Valor"
                                    v-money="money"
                                    v-model="paymentInitialized.value"
                                    :rules="{moneyGreaterThanZero: true}">
                          <span slot="prepend">R$</span>
                        </base-input>
                      </div>
                    </div>
                    <!-- CHARGE -->
                    <div v-if="!isNull(paymentInitialized.chargeInfo)">
                      <div class="row">
                        <div class="col">
                          <div class="my-3">
                          <span class="h7 surtitle text-muted">
                            Dados da cobrança
                          </span>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <span class="h6 surtitle text-muted">Chave</span>
                          <span class="d-block h4">{{paymentInitialized.chargeInfo.key}}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <span class="h6 surtitle text-muted">Criação</span>
                          <span class="d-block h4">{{paymentInitialized.chargeInfo.creationDate}}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <span class="h6 surtitle text-muted">Validade</span>
                          <span class="d-block h4" v-if="!isNullOrEmpty(paymentInitialized.chargeInfo.creationDate)">até {{paymentInitialized.chargeInfo.dueDate}}</span>
                          <span class="d-block h4" v-if="!isNullOrEmpty(paymentInitialized.chargeInfo.expiration)">por {{paymentInitialized.chargeInfo.expiration}} segundos</span>
                          <span class="d-block h4" v-if="paymentInitialized.chargeInfo.payableAfterDue === 'true'">Aceita pagamento após o vencimento</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <span class="h6 surtitle text-muted">Pagador Original</span>
                          <span class="d-block h4">{{paymentInitialized.chargeInfo.payer.name}}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <span class="h6 surtitle text-muted">Documento do Pagador Original</span>
                          <span class="d-block h4">{{paymentInitialized.chargeInfo.payer.documentNumber|VueMaskFilter(chargedPayerDocumentMask)}}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <span class="h6 surtitle text-muted">Valor Original</span>
                          <span class="d-block h4">{{paymentInitialized.chargeInfo.valueCompositionOriginal}}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <span class="h6 surtitle text-muted">Desconto</span>
                          <span class="d-block h4">{{paymentInitialized.chargeInfo.valueCompositionDiscount}}</span>
                        </div>

                        <div class="col">
                          <span class="h6 surtitle text-muted">Juros</span>
                          <span class="d-block h4">{{paymentInitialized.chargeInfo.valueCompositionInterest}}</span>
                        </div>

                        <div class="col">
                          <span class="h6 surtitle text-muted">Multa</span>
                          <span class="d-block h4">{{paymentInitialized.chargeInfo.valueCompositionFine}}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <span class="h6 surtitle text-muted">Valor Final</span>
                          <span class="d-block h4">{{paymentInitialized.chargeInfo.valueCompositionFinal}}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <span class="h6 surtitle text-muted">Mensagem do Cobrador</span>
                          <span class="d-block h4">{{paymentInitialized.chargeInfo.solicitation}}</span>
                        </div>
                      </div>
                    </div>
                    <!-- FREE FIELD -->
                    <div class="row">
                      <div class="col">
                        <span class="h6 surtitle text-muted">Campo Livre</span>
                        <base-input
                          type="text"
                          name="Campo Livre"
                          placeholder="Envie uma mensagem para o recebedor"
                          :rules="{max:60}"
                          v-model="paymentInitialized.freeField">
                        </base-input>
                      </div>
                    </div>
                    <div class="row text-center">
                      <div class="col-md-12 mb-3">
                        <button type="button" class="btn btn-primary" @click="cancelTransaction">
                          <span class="btn-label"><i class="fas fa-times"></i></span> Cancelar
                        </button>
                        <button type="button" class="btn btn-primary" v-bind:disabled="sendingPix"
                                @click.prevent="handleSubmit(confirmTransaction)">
                          <span class="btn-label"><i class="fa fa-arrow-up"></i></span> Confirmar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </validation-observer>
            </div>
            <div class="col-md-6" v-if="!showHistory">
              <card
                header-classes="bg-transparent"
                body-classes="px-lg-7"
                class="card-pricing border-0 text-center mb-4">

                <!--Header-->
                <h4 slot="header" class="text-uppercase ls-1 text-primary py-3 mb-0">Envie um PIX para outros
                  bancos!</h4>

                <!--Body-->
                <div class="display-2">R$ {{serviceFee}}</div>
                <span class=" text-muted">por PIX</span>
                <ul class="list-unstyled my-4">
                  <li>
                    <div class="d-flex text-left">
                      <div>
                        <div class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                          <i class="fas fa-arrow-right"></i>
                        </div>
                      </div>
                      <div>
                        <span class="pl-2 text-sm">Envie qualquer valor à outro banco.</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="d-flex text-left">
                      <div>
                        <div class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                          <i class="fas fa-arrow-right"></i>
                        </div>
                      </div>
                      <div>
                        <span class="pl-2 text-sm">Confira atentamente os dados do PIX de destino.</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="d-flex text-left">
                      <div>
                        <div class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                          <i class="fas fa-arrow-right"></i>
                        </div>
                      </div>
                      <div>
                        <span class="pl-2 text-sm">Esta operação não possui limitações de horário.</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="d-flex text-left">
                      <div>
                        <div class="icon icon-xs icon-shape bg-gradient-primary text-white shadow rounded-circle">
                          <i class="fas fa-arrow-right"></i>
                        </div>
                      </div>
                      <div>
                          <span
                            class="pl-2 text-sm">A transação irá levar apenas alguns instantes para ser concluída.</span>
                      </div>
                    </div>
                  </li>
                </ul>
              </card>
            </div>
            <div class="col-md-12" v-if="showHistory">
              <h4 slot="header" class="card-title">
                Selecione um Destino
              </h4>
              <div class="row clickable-rows">
                <div class="col-md-12">
                  <div class="col-sm-12 mt-2">
                    <el-table class="table-striped"
                              :data="tableData"
                              @row-click="onBankReuseSelected"
                              style="width: 100%; cursor: pointer"
                              empty-text="Nenhuma transação encontrada no período informado.">
                      <el-table-column :min-width="130" label="Nome">
                        <template slot-scope="props">
                          <span>
                            <span><b>{{props.row.receiverName }}</b></span>
                          </span>
                        </template>
                      </el-table-column>
                      <el-table-column :min-width="100" label="CPF/CNPJ">
                        <template slot-scope="props">
                          <span>
                            <span><b>{{props.row.receiverDocument}}</b></span>
                          </span>
                        </template>
                      </el-table-column>
                      <el-table-column :min-width="110" label="Tipo de Chave">
                        <template slot-scope="props">
                          <span>
                            <span><b>{{getKeyTypeLabel(props.row.paymentType, props.row.keyType)}}</b></span>
                          </span>
                        </template>
                      </el-table-column>
                      <el-table-column :min-width="110" label="Chave">
                        <template slot-scope="props">
                          <span>
                            <span><b>{{props.row.keyValue}}</b></span>
                          </span>
                        </template>
                      </el-table-column>
                      <el-table-column :min-width="110" label="Banco">
                        <template slot-scope="props">
                          <span>
                            <span><b>{{props.row.bankName}}</b></span>
                          </span>
                        </template>
                      </el-table-column>
                      <el-table-column :min-width="110" label="Ag./Co.">
                        <template slot-scope="props">
                          <span>
                            <span><b>{{props.row.branch}}/{{props.row.number}}</b></span>
                          </span>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                  <div class="float-right mt-3 mb-2">
                    <base-pagination class="pull-right"
                                     v-model="pagination.currentPage"
                                     :per-page="pagination.perPage"
                                     :total="pagination.totalNumber"
                                     v-on:input="this.loadData">
                    </base-pagination>
                  </div>
                </div>
              </div>
              <div class="row text-center">
                <div class="col-md-12">
                  <button type="info" class="btn btn-primary" @click="showHistory = false">
                    <i slot="label" class="fas fa-undo"></i> Voltar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {Option, Select, Table, TableColumn} from 'element-ui';
  import {BasePagination} from '@/components';
  import {isNull, isNullOrEmpty, moneyToFloat, notNull, notNullOrEmpty, toMoney} from "@/util/core.utils"
  import {failWs, getWs, postWs} from '@/ws.service'
  import banksCodes from '@/util/banks-codes'
  import Autocomplete from 'vuejs-auto-complete'
  import {VueMaskFilter} from 'v-mask'

  export default {
    components: {
      BasePagination,
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      Autocomplete
    },
    filters: {
      VueMaskFilter
    },
    data() {
      return {
        docsOk: false,
        validated: false,
        sendingPix: false,
        value: '0,00',
        valueChangeAllowed: false,
        receiverDocumentMask: '',
        payerDocumentMask: '',
        chargedPayerDocumentMask: '',
        money: {
          decimal: ',',
          thousands: '.',
          prefix: '',
          suffix: '',
          precision: 2,
          masked: false /* doesn't work with directive */
        },
        destinationIdType: '',
        serviceFee: '0,50',
        validateIntervalLoop: null,
        accountBalance: '0,00',
        transfer2Key: {
          key: {
            value: '',
            type: ''
          }
        },
        transfer2Account: {
          bankCode: '',
          branch: '',
          number: '',
          accountType: '',
          receiverName: '',
          receiverDocumentType: '',
          receiverDocument: ''
        },
        transfer2Qr: {
          value: ''
        },
        paymentInitialized: {
          paymentId: '',
          payer: {
            bankName: '',
            bankAccountBranch: '',
            bankAccountNumber: '',
            bankAccountType: '',
            name: '',
            documentNumber: '',
            documentType: ''
          },
          receiver: {
            bankName: '',
            bankAccountBranch: '',
            bankAccountNumber: '',
            bankAccountType: '',
            bankAccountTypeHR: '',
            name: '',
            documentNumber: '',
            documentType: ''
          },
          value: '0,00',
          chargeType: '',
          freeField: '',
          chargeInfo: {
            creationDate: '',
            presentationDate: '',
            expiration: '',
            dueDate: '',
            payableAfterDue: '',
            revision: '',
            version: '',
            payer: {
              bankName: '',
              bankAccountBranch: '',
              bankAccountNumber: '',
              bankAccountType: '',
              bankAccountTypeHR: '',
              name: '',
              documentNumber: '',
              documentType: '',
            },
            valueCompositionOriginal: '',
            valueCompositionFinal: '',
            valueCompositionInterest: '',
            valueCompositionFine: '',
            valueCompositionDiscount: '',
            valueCompositionAllowModification: '',
            key: {
              value: '',
              type: ''
            },
            solicitation: '',
            additionalInformation: ''
          },
          status: '',
          internalTransactionId: ''
        },
        showHistory: false,
        tableData: [],
        pagination: {
          perPage: 5,
          currentPage: 1,
          pageMaxNumber: 1,
          fromNumber: 0,
          toNumber: 0,
          totalNumber: 0
        },
        propsToSearch: ['destinationName', 'destinationSocialNumber', 'destinationKeyType', 'destinatioKeyValue'],
        banksCodes: banksCodes,
      }
    },
    created() {
      this.docsOk = localStorage.getItem("docsOk") == 'true'
      if (!this.docsOk) {
        return
      }
      this.accountBalance = notNull(localStorage.getItem('accountBalance'), '0,00')
      this.loadFee()
    },
    methods: {
      toMoney,
      moneyToFloat,
      isNullOrEmpty,
      notNullOrEmpty,
      isNull,
      doTransaction() {
        if (isNullOrEmpty(this.destinationIdType)) {
          this.$swal({
            title: 'Aviso!',
            text: 'O tipo de identificação é obrigatório.',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-warning btn-fill'
            },
            timer: 5000,
            icon: 'warning'
          })
          return
        }

        if (this.destinationIdType === 'MAN') {
          this.callManualPixInitWs()
        } else if (this.destinationIdType === 'QRCODE') {
          this.callQrCodeInitWs();
        } else {
          this.callKeyPixInitWs()
        }

      },
      callQrCodeInitWs() {
        postWs("/pix/direct/payment/initiate-qr-payment",
          true, {qrCode: this.transfer2Qr.value}, null,
          (response) => {
            let text = 'Falha ao transferir! Tente mais tarde.'
            if (response.data.status === 'SUCCESS') {
              this.populatePaymentInfo(response.data);
              return
            }
            this.$swal({
              title: 'Aviso!',
              text: text,
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-warning btn-fill'
              },
              icon: 'warning'
            })
          },
          this.handlerCatchForInit()
        )
      },
      callManualPixInitWs() {
        postWs("/pix/direct/payment/initiate-manual-payment",
          true, null,
          this.transfer2Account,
          (response) => {
            let text = 'Falha ao transferir! Tente mais tarde.'
            if (response.data.status === 'SUCCESS') {
              this.populatePaymentInfo(response.data);
              return
            }
            this.$swal({
              title: 'Aviso!',
              text: text,
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-warning btn-fill'
              },
              icon: 'warning'
            })
          },
          this.handlerCatchForInit()
        )
      },
      callKeyPixInitWs() {
        this.transfer2Key.key.type = this.destinationIdType

        postWs("/pix/direct/payment/initiate-key-payment",
          true, null,
          this.transfer2Key,
          (response) => {
            let text = 'Falha ao transferir! Tente mais tarde.'
            if (response.data.status === 'SUCCESS') {
              this.populatePaymentInfo(response.data);
              return
            }
            this.$swal({
              title: 'Aviso!',
              text: text,
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-warning btn-fill'
              },
              icon: 'warning'
            })
          },
          this.handlerCatchForInit()
        )
      },
      handlerCatchForInit: function () {
        return (error) => {
          let text = null
          if (error.response.data.status == 'NO_MOBILE_DEVICE_REGISTERED') {
            text = 'Nenhum dispositivo de verificação cadastrado! Para prosseguir, cadastre clicando no menu \'CADASTRAR DISPOSITIVO\'.'
          } else if (error.response.data.status == 'ACCOUNT_DOCUMENTS_NOT_VALIDATED') {
            text = 'Conta ainda não verificada! Para prosseguir, favor preencher os dados e enviar a documentação clicando no menu \'VERIFICAR CONTA\'.'
          } else if (error.response.data.status == 'SOURCE_USER_HAVE_NO_ENABLED_ACCOUNT' ||
            error.response.data.status == 'BLOCKED_OUT_TRANSACTIONS' ||
            error.response.data.status == 'BLOCKED') {
            text = 'Sua conta foi bloqueada! Entre em contato com nosso suporte.'
          } else if (error.response.data.status == 'SOURCE_USER_INACTIVE') {
            text = 'Sua conta está inativa! Entre em contato com nosso suporte.'
          } else if (error.response.data.status == 'DICT_NOT_FOUND') {
            text = 'Não foi possível encontrar a chave informada no diretório do PIX! Por favor, verifique e tente novamente.'
          } else if (error.response.data.status == 'RECEIVER_EQUALS_PAYER') {
            text = 'Ops. Parece que esta pessoa já possui uma conta em nosso sistema. Por favor realize uma transferência entre contas utilizando o nome de usuário da conta!'
          } else if (error.response.data.status == 'INVALID_QRCODE') {
            text = 'O QR Code informado está inválido. Por favor confira e tente novamente.'
          } else if (error.response.data.response == 'OPERATION_EXCEEDS_LIMIT') {
            text = 'Esta transação excede os limites para sua conta. Entre em contato com nosso suporte para solicitar um aumento no seu limite.'
          }
          if (!isNullOrEmpty(text)) {
            this.$swal({
              title: 'Aviso!',
              text: text,
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-warning btn-fill'
              },
              icon: 'warning'
            })
          } else {
            this.$swal({
              title: 'Serviço temporariamente indisponível!',
              text: 'Tente novamente mais tarde.',
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-danger btn-fill'
              },
              timer: 5000,
              icon: 'error'
            })
          }
        };
      },
      cancelTransaction() {
        this.clearAll()
      },
      confirmTransaction() {
        this.sendingPix = true

        if (moneyToFloat(this.paymentInitialized.value) <= 0) {
          this.$swal({
            title: 'Aviso!',
            text: 'Falha ao transferir! O valor deve ser maior que zero!.',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-warning btn-fill'
            },
            icon: 'warning'
          })

          this.sendingPix = false
          return;
        }

        let valueAndFee = (moneyToFloat(this.paymentInitialized.value) + moneyToFloat(this.serviceFee))
        if (valueAndFee > moneyToFloat(this.accountBalance)) {
          this.$swal({
            title: 'Aviso!',
            text: 'Você não possui saldo suficiente.',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-warning btn-fill'
            },
            icon: 'warning'
          })
          this.sendingPix = false
          return
        }

        this.$swal({
          title: 'Confirma a transação ?',
          html: "NOME DESTINO: <b>" + this.paymentInitialized.receiver.name + "</b> <br/>" +
            "DOC. DESTINO: <b>" + this.paymentInitialized.receiver.documentNumber + "</b> <br/>" +
            "VALOR: <b>R$ " + this.paymentInitialized.value + "</b> <br/>",
          icon: 'info',
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: 'SIM',
          cancelButtonText: 'cancelar',
          customClass: {
            confirmButton: 'btn btn-success btn-fill',
            cancelButton: 'btn btn-danger btn-fill'
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.callConfirmPaymentWs()
          } else {
            this.sendingPix = false
          }
        })
      },
      callConfirmPaymentWs() {
        postWs("/pix/direct/payment/confirm-payment", true, null,
          {
            internalTransactionId: this.paymentInitialized.internalTransactionId,
            payerValue: this.valueChangeAllowed ? moneyToFloat(this.paymentInitialized.value) : null,
            payerFreeField: this.paymentInitialized.freeField
          },
          (response) => {
            this.sendingPix = false
            if (response.status === 200) {
              this.success();
            }
          },
          (error) => {
            this.sendingPix = false
            if (error == null
              || error.response.status == 412) {
              return
            }

            let text = 'Falha ao transferir! Tente mais tarde.'
            this.$swal({
              title: 'Aviso!',
              text: text,
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-warning btn-fill'
              },
              icon: 'warning'
            })
          },
          true)
      },
      populatePaymentInfo(paymentData) {
        this.paymentInitialized = paymentData
        this.paymentInitialized.value = toMoney(paymentData.value)

        if (this.paymentInitialized.value === toMoney(0) ||
          (!isNull(this.paymentInitialized.chargeInfo) && this.paymentInitialized.chargeInfo.valueCompositionAllowModification)) {
          this.valueChangeAllowed = true;
        } else {
          this.valueChangeAllowed = false;
        }

        const cpfMask = 'XXX.XXX.XXX-XX';
        const cnpjMask = 'XX.XXX.XXX/XXXX-XX';

        if (notNull(this.paymentInitialized.receiver.documentType === 'CPF')) {
          this.receiverDocumentMask = cpfMask;
        } else if (notNull(this.paymentInitialized.receiver.documentType === 'CNPJ')) {
          this.receiverDocumentMask = cnpjMask;
        }

        if (notNull(this.paymentInitialized.payer.documentType === 'CPF')) {
          this.payerDocumentMask = cpfMask;
        } else if (notNull(this.paymentInitialized.payer.documentType === 'CNPJ')) {
          this.payerDocumentMask = cnpjMask;
        }

        if (!isNull(this.paymentInitialized.chargeInfo) && !isNull(this.paymentInitialized.chargeInfo.payer)) {
          if (notNull(this.paymentInitialized.chargeInfo.payer.documentType === 'CPF')) {
            this.chargedPayerDocumentMask = cpfMask;
          } else if (notNull(this.paymentInitialized.chargeInfo.payer.documentType === 'CNPJ')) {
            this.chargedPayerDocumentMask = cnpjMask;
          }
        }
      },
      clearAll() {
        this.transfer2Key.key.value = ''
        this.transfer2Key.key.type = ''

        this.transfer2Account.bankCode = ''
        this.transfer2Account.branch = ''
        this.transfer2Account.number = ''
        this.transfer2Account.accountType = ''
        this.transfer2Account.receiverName = ''
        this.transfer2Account.receiverDocument = ''
        this.transfer2Account.receiverDocumentType = ''

        this.paymentInitialized.paymentId = '';

        this.paymentInitialized.payer.bankName = ''
        this.paymentInitialized.payer.bankAccountBranch = ''
        this.paymentInitialized.payer.bankAccountNumber = ''
        this.paymentInitialized.payer.bankAccountType = ''
        this.paymentInitialized.payer.name = ''
        this.paymentInitialized.payer.documentNumber = ''
        this.paymentInitialized.payer.documentType = ''

        this.paymentInitialized.receiver.bankName = ''
        this.paymentInitialized.receiver.bankAccountBranch = ''
        this.paymentInitialized.receiver.bankAccountNumber = ''
        this.paymentInitialized.receiver.bankAccountType = ''
        this.paymentInitialized.receiver.name = ''
        this.paymentInitialized.receiver.documentNumber = ''
        this.paymentInitialized.receiver.documentType = ''

        this.paymentInitialized.value = toMoney(0)
        this.paymentInitialized.chargeType = ''
        this.paymentInitialized.freeField = ''

        if (!isNull(this.paymentInitialized.chargeInfo)) {
          this.paymentInitialized.chargeInfo.creationDate = ''
          this.paymentInitialized.chargeInfo.presentationDate = ''
          this.paymentInitialized.chargeInfo.expiration = ''
          this.paymentInitialized.chargeInfo.dueDate = ''
          this.paymentInitialized.chargeInfo.payableAfterDue = ''
          this.paymentInitialized.chargeInfo.revision = ''
          this.paymentInitialized.chargeInfo.version = ''

          this.paymentInitialized.chargeInfo.payer.bankName = ''
          this.paymentInitialized.chargeInfo.payer.bankAccountBranch = ''
          this.paymentInitialized.chargeInfo.payer.bankAccountNumber = ''
          this.paymentInitialized.chargeInfo.payer.bankAccountType = ''
          this.paymentInitialized.chargeInfo.payer.name = ''
          this.paymentInitialized.chargeInfo.payer.documentNumber = ''
          this.paymentInitialized.chargeInfo.payer.documentType = ''

          this.paymentInitialized.chargeInfo.valueCompositionOriginal = ''
          this.paymentInitialized.chargeInfo.valueCompositionFinal = ''
          this.paymentInitialized.chargeInfo.valueCompositionInterest = ''
          this.paymentInitialized.chargeInfo.valueCompositionFine = ''
          this.paymentInitialized.chargeInfo.valueCompositionDiscount = ''
          this.paymentInitialized.chargeInfo.valueCompositionAllowModification = ''

          this.paymentInitialized.chargeInfo.key.value = ''
          this.paymentInitialized.chargeInfo.key.type = ''

          this.paymentInitialized.chargeInfo.solicitation = ''
          this.paymentInitialized.chargeInfo.additionalInformation = ''
        }

        this.paymentInitialized.status = ''
        this.paymentInitialized.internalTransactionId = ''
      },
      success() {
        clearInterval(this.validateIntervalLoop)
        this.accountBalance = toMoney(moneyToFloat(this.accountBalance) - moneyToFloat(this.paymentInitialized.value) - moneyToFloat(this.serviceFee))
        localStorage.setItem('accountBalance', this.accountBalance)
        this.clearAll()
        this.$swal({
          title: 'Sucesso!',
          text: 'Valor transferido com sucesso!',
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-success btn-fill'
          },
          timer: 5000,
          icon: 'success'
        })
      },
      loadHistory() {
        this.showHistory = true
        postWs("/transaction/pix-condensed-history",
          true, null,
          {
            pageNumber: (this.pagination.currentPage - 1),
            pageSize: this.pagination.perPage
          },
          this.successLoadData
          , () => {
            this.tableData = []
          })
      },
      onBankReuseSelected(destination) {
        this.showHistory = false
        this.clearAll()
        if (destination.paymentType == 'KEY') {
          this.destinationIdType = destination.keyType
        } else if (destination.paymentType == 'MANUAL') {
          this.destinationIdType = 'MAN'
        }
        setTimeout(function (vueComponent, destination, progress) {
          vueComponent.selectDestination(destination)
        }, 500, this, destination)
      },
      selectDestination(destination) {
        if (destination.paymentType == 'KEY') {
          this.transfer2Key.key.type = destination.keyType
          this.transfer2Key.key.value = destination.keyValue
        } else if (destination.paymentType == 'MANUAL') {
          this.transfer2Account.bankCode = destination.bankCode
          this.transfer2Account.branch = destination.branch
          this.transfer2Account.number = destination.number
          this.transfer2Account.accountType = destination.accountType
          this.transfer2Account.receiverName = destination.receiverName
          this.transfer2Account.receiverDocumentType = destination.receiverDocumentType
          this.transfer2Account.receiverDocument = destination.receiverDocument
          this.$refs.bankAc.display = this.displayBankName(this.getBank(destination.bankCode))
          this.$refs.bankAc.value = destination.bankCode
        }
      },
      getBank(code) {
        for (let bank in this.banksCodes) {
          if (this.banksCodes[bank].code == code) {
            return this.banksCodes[bank]
          }
        }
        return null
      },
      selectBank(bank) {
        this.transfer2Account.bankCode = bank.selectedObject.code
      },
      displayBankName(bank) {
        return bank.code + ' - ' + bank.name
      },
      loadData() {
        postWs("/transaction/pix-condensed-history",
          true, null,
          {
            pageNumber: (this.pagination.currentPage - 1),
            pageSize: this.pagination.perPage
          }, this.successLoadData, failWs)
      },
      successLoadData(response) {
        this.tableData = response.data.list
        this.pagination.totalNumber = response.data.totalNumber
        this.pagination.pageMaxNumber = response.data.pageMaxNumber
        if (this.pagination.totalNumber > 0) {
          this.pagination.fromNumber = ((this.pagination.perPage * (this.pagination.currentPage - 1)) + 1)
          this.pagination.toNumber = ((this.pagination.fromNumber + this.tableData.length) - 1)
        } else {
          this.pagination.fromNumber = 0
          this.pagination.toNumber = 0
        }
      },
      loadFee() {
        getWs("/fee/list-user-fees",
          true, null, this.successLoadDataFee,
          failWs)
      },
      successLoadDataFee(response) {
        for (let fee of response.data.list) {
          if (fee.type === 'PIX_PAYMENT') {
            var valueFee = toMoney(fee.value);
            this.serviceFee = valueFee;
          }
        }
      },
      getKeyTypeLabel(paymentType, keyType) {
        if (paymentType == 'MANUAL') {
          return "Agência e Conta"
        } else if (paymentType == 'QRCODE') {
          return "QR Code (Copia e Cola)"
        } else if (keyType == 'PHONE') {
          return "Celular"
        } else if (keyType == 'EMAIL') {
          return "E-mail"
        } else if (keyType == 'EVP') {
          return "Chave Aleatória"
        }
        return keyType
      },
      getError(fieldName) {
        return this.errors.first(fieldName)
      }
    }
  }

</script>
<style>
  .no-border-card .card-footer {
    border-top: 0;
  }
</style>
